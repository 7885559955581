













import { PropType } from 'vue';
import TransactionsTableCard from '@/views/StatisticsView/TransactionsTableCard.vue';
import { UserTransactionsParamsProps } from '@/api/schema';

export default {
  name: 'StatisticsView',
  components: {
    TransactionsTableCard
  },
  props: {
    paramsProps: {
      type: Object as PropType<UserTransactionsParamsProps>,
      default: (): UserTransactionsParamsProps => ({})
    }
  }
};
